<template>
  <div v-show="showFlag">
    <section class="modalTurn">
      <div class="modalTurn-content">
        <div class="modalWin">
          <h2>Очистка черновика</h2>
          <h4>Период</h4>
          <div>{{ dateFormatHuman(date_begin) }} - {{ dateFormatHuman(date_end) }}</div>
          <br />
          <div>Смен в черновике: {{ turnsDraftcnt ? turnsDraftcnt : "нет" }}</div>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button v-on:click="windowClose(false)" class="btn cancel" v-if="!deleteBegin">
            Отмена
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button ref="applyButton" v-on:click="Apply()" class="btn warn" v-if="!deleteBegin">
            Очистить
          </button>

          <div v-if="deleteEnd && turnsDraftcntDeleted">
            Удалено смен: {{ turnsDraftcntDeleted }}
          </div>

          <br />
          <button v-on:click="windowClose(true)" class="btn cancel" v-if="deleteEnd">
            Закрыть
          </button>
        </div>
      </div>
    </section>
  </div>
</template>


<style lang="scss" scoped>
.grey {
  color: grey;
  text-decoration: underline;
}
</style>

<script>
// import Loader from "@/components/Loader.vue";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";

export default {
  name: "ClearhWindow",
  components: {
    // Loader,
  },

  props: {
    showFlag: Boolean,
    date_begin: null,
    date_end: null,
    type: null,
  },

  data() {
    return {
      errMessage: null,
      turnsDraftcnt: 0,
      turnsDraftcntDeleted: 0,

      loading: true,
      deleteBegin: false,
      deleteEnd: false,
    };
  },
  watch: {
    showFlag: async function (newVal) {
      if (newVal) {
        this.loading = true;
        this.deleteBegin = false;
        this.deleteEnd = false;
        await this.turnsDraftCount();
        this.loading = false;
      }
    },
  },

  methods: {
    async turnsDraftCount() {
      this.turnsDraftcnt = await request("/api/turndraft/countbydates", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
    },

    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    async Apply() {
      // disable button for prevent turn dupliction
      this.deleteBegin = true;
      this.turnsDraftcntDeleted = await request("/api/turndraft/deletebydates", "DELETE", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });

      this.deleteEnd = true;
    },

    windowClose(flag) {
      // update view after close because of deleting
      this.$emit("close", flag);
    },
  },
};
</script>
