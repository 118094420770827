<template>
  <div v-show="showFlag">
    <section class="modalTurn">
      <div class="modalTurn-content">
        <div class="modalWin">
          <h2>Публикация черновика</h2>
          <h4>Период</h4>
          <div>{{ dateFormatHuman(date_begin) }} - {{ dateFormatHuman(date_end) }}</div>
          <br />
          <h4>Смены</h4>
          <div v-if="loading">
            <Loader />
          </div>
          <div v-else>
            <div>Смен в текущем расписании: {{ turnsRealcnt ? turnsRealcnt : "нет" }}</div>
            <div>Смен в черновике: {{ turnsDraftcnt ? turnsDraftcnt : "нет" }}</div>
            <br />
            <div class="err" v-if="turnsRealcnt">
              Смены в текущем расписании будут удалены, {{ turnsRealcnt }} шт.
            </div>
          </div>
          <br />

          &nbsp;&nbsp;&nbsp;&nbsp;
          <button v-on:click="windowClose(false)" class="btn cancel" v-if="!updateBegin">
            Отмена
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button ref="applyButton" v-on:click="Apply()" class="btn" v-if="!updateBegin">
            Опубликовать
          </button>

          <div v-if="updateEnd && turnsRealcntDeleted">
            Удалено смен: {{ turnsRealcntDeleted }}
          </div>
          <div v-if="updateEnd && turnsDraftcntCopied">
            Скопировано смен: {{ turnsDraftcntCopied }}
          </div>

          <br />
          <button v-on:click="windowClose(true)" class="btn cancel" v-if="updateEnd">
            Закрыть
          </button>
        </div>
      </div>
    </section>
  </div>
</template>


<style lang="scss" scoped>
.grey {
  color: grey;
  text-decoration: underline;
}
</style>

<script>
import Loader from "@/components/Loader.vue";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";

export default {
  name: "PublishWindow",
  components: {
    Loader,
  },

  props: {
    showFlag: Boolean,
    date_begin: null,
    date_end: null,
    type: null,
  },

  data() {
    return {
      errMessage: null,
      turnsRealcnt: 0,
      turnsRealcntDeleted: 0,
      turnsDraftcnt: 0,
      turnsDraftcntCopied: 0,

      loading: true,
      updateBegin: false,
      updateEnd: false,
    };
  },
  watch: {
    showFlag: async function (newVal) {
      if (newVal) {
        this.loading = true;
        this.updateBegin = false;
        this.updateEnd = false;
        await this.turnsRealCount();
        await this.turnsDraftCount();
        this.loading = false;
      }
    },
  },

  methods: {
    // keyPressHandler(evt) {
    //   if (evt.code == "Escape") this.windowClose(0, null);
    //   if (evt.code == "Enter") this.Apply();
    // },
    async turnsRealCount() {
      this.turnsRealcnt = await request("/api/turn/countbydates", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
    },

    async turnsDraftCount() {
      this.turnsDraftcnt = await request("/api/turndraft/countbydates", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
    },

    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    async Apply() {
      // disable button for prevent turn dupliction
      this.updateBegin = true;
      this.turnsRealcntDeleted = await request("/api/turn/deletebydates", "DELETE", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });

      this.turnsDraftcntCopied = await request("/api/turn/updatefromdraft", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });

      this.updateEnd = true;
    },

    windowClose(flag) {
      this.$emit("close", flag);
    },
  },
};
</script>
